import { ILocalAudioTrack, IRemoteAudioTrack } from "agora-rtc-sdk-ng";
import React, { useEffect, useRef } from "react";

export interface VideoPlayerProps {
    audioTrack: ILocalAudioTrack | IRemoteAudioTrack | undefined;
}

export const MediaPlayer = (props: VideoPlayerProps) => {
    const container = useRef<HTMLDivElement>(null);
    useEffect(() => {
        props.audioTrack?.play();
        return () => {
            props.audioTrack?.stop();
        };
    }, [props.audioTrack]);
    return (
        <div ref={container} className="video-player" style={{ width: "320px", height: "240px" }}></div>
    );
}
